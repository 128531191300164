<template>
  <div class="lostCharts">
    <div class="search">
      <div class="searchItem">
        <el-select v-model="queryType" placeholder="选择类型" @change="queryTypeOptionsValueChange">
          <el-option v-for="item in queryTypeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <div class="searchItem">
        <el-cascader
          :disabled="queryType == 'haltReason' ? true : queryType == '' ? true : false"
          :show-all-levels="false"
          clearable
          collapse-tags
          v-model="factoryStructValue"
          placeholder="工厂结构"
          :props="Props"
          :options="factoryStructTree"
          @change="factoryStructhandleChange"
        ></el-cascader>
      </div>
      <div class="searchItem">
        <el-select multiple collapse-tags clearable :disabled="queryType !== 'device'" v-model="deviceIds" placeholder="选择设备">
          <el-option v-for="item in deviceOptions" :key="item.deviceId" :label="item.name" :value="item.deviceId"> </el-option>
        </el-select>
      </div>
      <div class="searchItem">
        <el-select :disabled="queryType !== 'device'" multiple collapse-tags clearable v-model="deviceTypeIds" placeholder="设备类型">
          <el-option v-for="item in DeviceTypeOptions" :key="item.deviceTypeId" :label="item.name" :value="item.deviceTypeId"> </el-option>
        </el-select>
      </div>
      <div class="searchItem">
        <el-cascader :show-all-levels="false" clearable collapse-tags v-model="lostReasonIdsValue" placeholder="停机原因" :props="lostProps" :options="lostReasonTree"></el-cascader>
      </div>
      <div class="searchItemTime">
        <Timerpick :vModel="date" startPlaceholder="开始时间" endPlaceholder="结束时间" :clearable="false" :editable="false" type="date" format="yyyy-MM-dd" />
      </div>
    </div>
    <div class="search">
      <div class="searchItemBtn">
        <el-button class="iconfont icon-dcsousuo" @click="searchCharts">查询</el-button>
        <el-button class="iconfont icon-dcdaochu1" @click="exportsCharts">导出</el-button>
      </div>
    </div>
    <baseSection name="停机损失TOP排名">
      <el-row>
        <el-col :offset="22" :span="2">
          <div class="switchBox" v-if="isCut">
            <span :title="title[0]" @click="change(1)" :class="ishistogram ? 'histogramactive' : 'histogram'"></span>
            <span :title="title[1]" @click="change(2)" :class="isline ? 'lineactive' : 'line'"></span>
            <span :title="title[2]" @click="change(3)" :class="iscurve ? 'curveactive' : 'curve'"></span>
          </div>
        </el-col>
      </el-row>
      <div class="chartsStatus">
        <div class="totalTimer" v-if="isCut">
          总时长:<span style="color: #f39800">{{ totalTimer }}</span>
        </div>
        <Histogram :ChartsData="ChartsData" v-if="ishistogram" />
        <Linecharts :ChartsData="ChartsData" v-if="isline" />
        <Curve :ChartsData="ChartsData" v-if="iscurve" />
      </div>
    </baseSection>
  </div>
</template>
<script>
import Timerpick from '@/components/TimerPick/index.vue';
import baseSection from '@/components/section/base-section';
import Histogram from './charts/histogram.vue';
import Linecharts from './charts/line.vue';
import Curve from './charts/curve.vue';
import { frontOneHour } from '../until.js';
import { msFormatter } from '@/axios/utils.js';
import domtoimage from 'dom-to-image';
export default {
  data() {
    return {
      // 类型被选中的值
      queryType: 'haltReason',
      // 类型数据源
      queryTypeOptions: [
        {
          value: 'haltReason',
          label: '停机原因'
        },

        {
          value: 'factory',
          label: '工厂'
        },
        {
          value: 'workShop',
          label: '车间'
        },
        {
          value: 'line',
          label: '产线'
        },
        {
          value: 'device',
          label: '设备'
        }
      ],
      // 设备类型组
      deviceTypeIds: [],
      // 设备类型数据源
      DeviceTypeOptions: [],
      // 日期选择
      date: [frontOneHour('yyyy-MM-dd', false), frontOneHour('yyyy-MM-dd', true)],
      // 工厂结构值
      factoryStructValue: [],
      // 工厂结构树数据源
      factoryStructTree: [],
      // 工厂结构选择器规则
      Props: {
        label: 'name',
        value: 'id',
        children: 'children',
        expandTrigger: 'hover',
        checkStrictly: true,
        multiple: true,
        disabled: 'disabled'
      },
      // 停机原因组
      lostReasonIdsValue: [],
      // 停机树数据源
      lostReasonTree: [],
      // 停机选择器规则
      lostProps: {
        label: 'reason',
        value: 'id',
        checkStrictly: true,
        multiple: true,
        expandTrigger: 'hover'
      },
      // 设备值
      deviceIds: [],
      // 设备数据源
      deviceOptions: [],
      // 图表相关
      title: ['柱状图', '曲线图', '曲线图'],
      isCut: false,
      ishistogram: false,
      isline: false,
      iscurve: false,
      ChartsData: null,
      totalTimer: ''
    };
  },
  components: { Timerpick, baseSection, Histogram, Linecharts, Curve },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      // 获取树形数据
      const treeRes = (await this.$apis.FactoryChart.list()).map(item => {
        return {
          ...item,
          label: item.name
        };
      });
      this.factoryStructTree = treeRes;
      // 获取设备类型数据
      const deviceTypeRes = await this.$apis.deviceTypeVirtual.deviceType();
      this.DeviceTypeOptions = deviceTypeRes;
      // 获取停机原因数据
      const lostres = await this.$apis.bugconfig.list();
      this.lostReasonTree = lostres;
      this.lostReasonIdsValue.push([this.lostReasonTree[0].id]);
      this.searchCharts();
    },
    // 树形禁用函数
    serDisabled(a) {
      let newLINE = JSON.parse(JSON.stringify(this.factoryStructTree));
      newLINE.forEach(item => {
        item.disabled = a === 'factory' ? false : a === 'device' ? false : true;
        if (item.children) {
          item.children.forEach(ele => {
            ele.disabled = a === 'workShop' ? false : a === 'device' ? false : true;
            if (ele.children) {
              ele.children.forEach(e => {
                e.disabled = a === 'line' ? false : a === 'device' ? false : true;
              });
            }
          });
        }
      });
      this.factoryStructTree = newLINE;
    },
    // 类型切换，就去重新设置禁用选项调用serDisabled函数
    queryTypeOptionsValueChange() {
      this.Props.multiple = true;
      this.Props.checkStrictly = true;
      switch (this.queryType) {
        case 'haltReason':
          break;
        case 'factory':
          this.serDisabled('factory');
          break;
        case 'workShop':
          this.serDisabled('workShop');
          break;
        case 'line':
          this.serDisabled('line');
          break;
        case 'device':
          this.Props.multiple = false;
          this.Props.checkStrictly = false;
          this.serDisabled('device');
          break;
      }
      // 类型切换数据清空
      this.factoryStructValue.length = 0;
      this.deviceTypeIds.length = 0;
      this.deviceIds.length = 0;
    },
    // 工厂结构值改变，然后分情况确定是否去获取设备
    async factoryStructhandleChange() {
      if (this.queryType !== 'device') {
        return false;
      }
      const data = {
        path: this.factoryStructValue[this.factoryStructValue.length - 1]
        // currentPageSize: 500
      };
      const deviceRes = await this.$apis.LSDconfig.getdevice(data);
      this.deviceOptions = deviceRes;
    },
    async searchCharts() {
      // 整合停机原因数据
      let lostReasonIds = [];
      this.lostReasonIdsValue.forEach(item => {
        lostReasonIds.push(item[item.length - 1]);
      });
      // 整合工厂结构数据
      let factoryStructIds = [];
      this.factoryStructValue.forEach(item => {
        factoryStructIds.push(item[item.length - 1]);
      });
      // 整合后端需要数据
      const data = {
        queryType: this.queryType === 'haltReason' ? this.queryType : this.queryType === 'device' ? this.queryType : 'factoryStruct',
        startDate: this.date[0],
        endDate: this.date[1],
        lostReasonIds,
        factoryStructIds,
        deviceIds: this.deviceIds,
        deviceTypeIds: this.deviceTypeIds
      };
      const res = await this.$apis.lostCharts.get(data);
      // 控制显示的图表
      this.isCut = true;
      this.ishistogram = true;
      this.isline = false;
      this.iscurve = false;
      // 时长
      let haltTotalPeriod = [];
      // 百分比
      let haltPeriodPercent = [];
      let total = 0;
      res.yaxisValues.forEach(item => {
        haltTotalPeriod.push((item.haltTotalPeriod / 1000 / 60).toFixed(0));
        total += item.haltTotalPeriod;
        haltPeriodPercent.push((item.haltPeriodPercent * 100).toFixed(2));
      });

      this.totalTimer = msFormatter(total, true);
      this.ChartsData = {
        xAxisValues: res.xaxisValues,
        haltTotalPeriod,
        haltPeriodPercent
      };
    },
    change(data) {
      switch (data) {
        case 1:
          this.ishistogram = true;
          this.isline = false;
          this.iscurve = false;
          break;
        case 2:
          this.ishistogram = false;
          this.isline = true;
          this.iscurve = false;
          break;
        case 3:
          this.ishistogram = false;
          this.isline = false;
          this.iscurve = true;
          break;
      }
    },
    exportsCharts() {
      if (!this.ChartsData) {
        this.$message.warning('暂无数据，无法导出');
        return;
      }
      domtoimage
        .toPng(document.querySelector('.chartsStatus'))
        .then(function (dataUrl) {
          let img = new Image();
          img.src = dataUrl;
          let a = document.createElement('a');
          a.setAttribute('href', dataUrl);
          a.setAttribute('download', '故障排名.png');
          a.click();
        })
        .catch(() => {
          this.$message.warning('无数据无法导出');
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  flex-wrap: wrap;
  .searchItem,
  .searchItemBtn {
    width: 200px;
    height: 36px;
    margin: 10px 16px 10px 0px;
    .icon-dcsousuo {
      transform: translateX(-2px);
    }
    .icon-dcdaochu1 {
      transform: translateX(2px);
    }
  }
  .searchItemBtn {
    display: flex;
    justify-content: space-around;
    /deep/.el-button {
      width: 90px;
    }
  }
  .searchItemTime {
    flex: 1;
    height: 36px;
    margin: 10px 16px 10px 0px;
    /deep/ & .el-input__inner {
      width: 220px;
    }
  }
}
.chartsStatus {
  position: relative;
  margin-top: 60px;
  .totalTimer {
    font-size: 16px;
    position: absolute;
    top: -5px;
    right: 9%;
  }
}
.switchBox {
  display: flex;
  justify-content: space-around;
  width: 150px;
}
.histogram {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: url('./icon/icon_charts01.png');
}
.histogramactive {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: url('./icon/icon_charts01active.png');
}
.line {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: url('./icon/icon_charts02.png');
}
.lineactive {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: url('./icon/icon_charts02active.png');
}
.curve {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: url('./icon/icon_charts03.png');
}
.curveactive {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: url('./icon/icon_charts03active.png');
}
</style>
