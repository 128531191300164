<template>
  <div class="lineCharts"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {};
  },
  props: {
    ChartsData: {
      type: Object,
      require: true,
      default: null
    }
  },
  mounted() {
    if (this.ChartsData !== null) {
      this.init();
    }
  },
  watch: {
    ChartsData() {
      this.init();
    },
    deep: true
  },
  methods: {
    init() {
      let chartDom = document.querySelector('.lineCharts');
      let myChart = echarts.init(chartDom);
      let option;

      option = {
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            let str = `${params[0].name}<br/>`;
            params.forEach(item => {
              if (item.seriesName === '时长') {
                let min = `${(item.value % 60).toFixed(0)}`;
                let h = `${(item.value / 60).toFixed(0)}`;
                str = str.concat(`${item.marker + item.seriesName}: ${h}小时${min}分钟<br/>`);
              } else {
                str = str.concat(`${item.marker + item.seriesName}: ${item.value} %<br/>`);
              }
            });
            return str;
          }
        },
        grid: {
          top: '8%',
          right: '3%',
          left: '5%',
          bottom: '20%'
        },
        legend: {
          data: ['百分比', '时长'],
          bottom: '10%',
          left: '5%',
          itemWidth: 13,
          itemHeight: 13,
          itemGap: 30
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.ChartsData.xAxisValues
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '百分比(%)',
            min: 0,
            max: 100,
            position: 'left',
            axisLabel: {
              formatter: '{value}'
            }
            // splitLine: {
            //   show: false
            // }
          },
          {
            type: 'value',
            name: '时长(min)',
            min: 0,
            position: 'right',
            offset: 0,
            axisLabel: {
              formatter: '{value}'
            },
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '百分比',
            type: 'line',
            data: this.ChartsData.haltPeriodPercent,
            itemStyle: {
              color: '#f39800',
              borderColor: '#fff'
            }
          },
          {
            name: '时长',
            type: 'line',
            yAxisIndex: 1,
            data: this.ChartsData.haltTotalPeriod,
            itemStyle: {
              color: '#ea3837',
              borderColor: '#fff'
            }
          }
        ]
      };

      option && myChart.setOption(option);
    }
  }
};
</script>

<style scoped>
.lineCharts {
  height: 600px;
}
</style>
